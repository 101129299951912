import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import DehazeIcon from '@material-ui/icons/Dehaze';

import logoImg from 'assets/images/ym-logo.png';

import classes from './styles.module.scss';

import { logout } from 'redux/actions/userAction';

const ButtonAppBar = ({ history, isLoggedIn, logout }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };
  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  const isMobileWd = useMediaQuery('(max-width: 768px)');

  return (
    <div>
      <AppBar position="fixed" className={classes.headerBar} id="nav-bar">
        <Toolbar disableGutters={isMobileWd}>
          <div
            className={classes.logo}
            onClick={() => {
              history.push('/');
              window.scrollTo(0, 0);
            }}
          >
            <img src={logoImg} style={{ height: isMobileWd ? '60%' : '100%' }} alt="" />
          </div>
          <Link href="/how" className={classes.menuItem}>
            How it works
          </Link>
          <Link href="/about" className={classes.menuItem}>
            About us
          </Link>
          <Link href="/projects" className={classes.menuItem}>
            Past projects
          </Link>
          <Link href="/pricing" className={classes.menuItem}>
            Pricing
          </Link>
          {process.env.REACT_APP_DEV_ENV !== 'prod' &&
            (!isLoggedIn ? (
              <Link href="/login" className={classes.menuItem}>
                Login
              </Link>
            ) : (
              <Link
                href=""
                className={classes.menuItem}
                onClick={() => {
                  localStorage.clear();
                  logout();
                  history.push('/');
                }}
              >
                Logout
              </Link>
            ))}
          <div className={classes.mobileVersion}>
            <Button
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <DehazeIcon />
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={handleClose} component="ul">
                          <Link href="/how" className={classes.linkItem}>
                            How it works
                          </Link>
                        </MenuItem>
                        <MenuItem onClick={handleClose} component="ul">
                          <Link href="/about" className={classes.linkItem}>
                            About us
                          </Link>
                        </MenuItem>
                        <MenuItem onClick={handleClose} component="ul">
                          <Link href="/projects" className={classes.linkItem}>
                            Past projects
                          </Link>
                        </MenuItem>
                        <MenuItem onClick={handleClose} component="ul">
                          <Link href="/pricing" className={classes.linkItem}>
                            Pricing
                          </Link>
                        </MenuItem>
                        {/* <MenuItem onClick={handleClose} component="ul">
                          <Link href="/policy" className={classes.linkItem}>
                            Privacy Policy
                          </Link>
                        </MenuItem>
                        <MenuItem onClick={handleClose} component="ul">
                          <Link href="/terms" className={classes.linkItem}>
                            Terms & Conditions
                          </Link>
                        </MenuItem> */}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const mapStateToProps = state => {
  return { isLoggedIn: state.user.isLoggedIn };
};

export default compose(connect(mapStateToProps, logout), withRouter)(ButtonAppBar);
