import { login, signup, getUserInfo } from 'clients/api';
import { toastr } from 'react-redux-toastr';

export const loginAction = (data, history) => async dispatch => {
  const res = await login(data);
  if (res.status >= 400) {
    toastr.error('Email or password is invalid');
  } else {
    localStorage.setItem('token', res.data.token);
    dispatch({ type: 'LOGIN', payload: { email: res.data.email, username: res.data.username } });
    history.push('/');
  }
};

export const logout = () => dispatch => {
  dispatch({ type: 'LOGOUT' });
};

export const signupAction = (data, history) => async dispatch => {
  const res = await signup(data);
  if (res.status >= 400) {
    toastr.error('Email or password is invalid');
  } else {
    localStorage.setItem('token', res.data.token);
    dispatch({ type: 'LOGIN', payload: { email: res.data.email, username: res.data.username } });
    history.push('/');
  }
};

export const loginCheck = () => async dispatch => {
  const res = await getUserInfo();
  if (res.status >= 200 && res.status < 300) {
    localStorage.setItem('token', res.data.token);
    dispatch({ type: 'GET_USER', payload: { email: res.data.email, username: res.data.username } });
  }
};
