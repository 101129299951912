import React, { Suspense, lazy } from 'react';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { loginCheck } from 'redux/actions/userAction';
import store from './redux/createStore';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import SuspenseFallback from 'component/shared/SuspenseFallback';
import Header from 'component/Navigation';
import Footer from 'component/footer';
import SEO from 'component/SEO';

const Home = lazy(() => import('Pages/Home'));
const AboutUs = lazy(() => import('Pages/AboutUs'));
const HowItWorks = lazy(() => import('Pages/HowItWorks'));
const Login = lazy(() => import('Pages/Login'));
const SignUp = lazy(() => import('Pages/SignUp'));
const Pricing = lazy(() => import('Pages/Pricing'));
const PastProject = lazy(() => import('Pages/PastProject'));
const FAQ = lazy(() => import('Pages/FAQ'));
const PlantingDesign = lazy(() => import('Pages/PricingDetails/PlantingDesign'));
const PartialYard = lazy(() => import('Pages/PricingDetails/PartialYard'));
const CompleteYard = lazy(() => import('Pages/PricingDetails/CompleteYard'));
const Policy = lazy(() => import('Pages/Policy'));
const TermsAndConditions = lazy(() => import('Pages/Terms'));
const BlogLists = lazy(() => import('Pages/BlogLists'));
const Blog = lazy(() => import('Pages/Blog'));

const ThankYou = lazy(() => import('Pages/Landing/Thankyou'));
const ThankYouAppt = lazy(() => import('Pages/Landing/ThankyouAppt'));

const history = createBrowserHistory();

if (localStorage.token) {
  store.dispatch(loginCheck());
}

const App = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <div>
          <SEO
            title={`YardMap | Online Landscape Design That Saves You Time and Money`}
            description={`Bring your backyard ideas to life with YardMap's online landscape design service. View pricing or request a free consultant today!`}
            profileURL={'https://www.yardmap.com/'}
          />
          <Header />
          <Suspense fallback={<SuspenseFallback />}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/about" component={AboutUs} />
              <Route path="/how" component={HowItWorks} />
              <Route path="/projects" component={PastProject} />
              <Route path="/login" component={Login} />
              <Route path="/signup" component={SignUp} />
              <Route path="/policy" component={Policy} />
              <Route path="/terms" component={TermsAndConditions} />
              <Route path="/pricing/plantingdesign" component={PlantingDesign} />
              <Route path="/pricing/partialyard" component={PartialYard} />
              <Route path="/pricing/completeyard" component={CompleteYard} />
              <Route path="/pricing" component={Pricing} />
              <Route path="/faq" component={FAQ} />
              <Route path="/blogs" component={BlogLists} />
              <Route path="/blog/:id" component={Blog} />
              {/* landing Pages */}
              <Route path="/thank-you" component={ThankYou} />
              <Route path="/thank-you-online-appt" component={ThankYouAppt} />
              <Redirect from="*" to="/" />
            </Switch>
          </Suspense>
          <Footer />
        </div>
      </Router>
      <ReduxToastr
        timeOut={10000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </Provider>
  );
};

export default App;
