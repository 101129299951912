import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import ActiveCampaignReducer from './ActiveCampaignReducer';
import userReducer from './userReducer';

const reducers = combineReducers({
  toastr: toastrReducer,
  activeCampaign: ActiveCampaignReducer,
  user: userReducer,
});

export default reducers;
