import React from 'react';
import ReactDOM from 'react-dom';

import { MuiThemeProvider as NewMuiThemeProvider } from '@material-ui/core/styles';
import TagManager from 'react-gtm-module';

import App from './App';
import { ApolloProvider } from 'react-apollo';
import ApolloClient from 'apollo-boost';
import { darkTheme } from './config/theme/index';

import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

const tagManagerArgs = {
  gtmId: 'GTM-5W4CDG3',
};

TagManager.initialize(tagManagerArgs);

const token =
  'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE1OTk3NjY3NjgsImF1ZCI6WyJodHRwczovL2FwaS11cy13ZXN0LTIuZ3JhcGhjbXMuY29tL3YyL2NrZWx4dG02eDF0Mm0wMXdoMHc5NmFkeDIvbWFzdGVyIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiNWE0NTdmYWYtNTc0NS00YTRmLTk4YWEtZTRkOGU2NTcxZDRkIiwianRpIjoiY2tleDd0dW54MG85ZDAxeHRlaWRpNjl5eSJ9.Fi_oYQZMjqC36iur5wqI-qfRwsBit9J4UWEN-OwqTQ32lE6RsIvv14PeChWBm-Cn2UT0LLHJYLwvSZP5LWAnMm0pxHM88N1WIneGrez99YMxUv9jRcORb2StXSyY1rqLeunlNMqdzF52Oz9ENGHruYcLcSi25ky13DG3M6fhePEGeYsS-bz8WKUAZDgfAjp-AeiQZ-0SjfW_ibClSZhphtHVR4vepr9gSlODgNfv-uFm0l3EkAhJu-csAfvRjLfDKibdXEooYmdX-MbChQ9e7UDgi1YgK-bZ2TE4y8ES2IkpJTVlRMQIBvXMtcSTyNTaVAxYcKitsgPGElI90swYXbGRhzT1ieg24jbfnK3hcbvxNG0efPhILWa8BRBnwL0PTxR1NKyLEMNywLaWCk0UP1e-JU0NGGtiAYKWcGbO9KECBrmZ_Ki1kHusmovuougc2lSSnjLlATZxrR0Kks_6jj_gKAFRomqsiRW973O-UyMAir3cg6Cyh5aVWluP97OaQvgD-CQtNJdTnM9eGeksWznVSYNtFwq-lKAFkbukPn8KIBmWXUKA3lcPUIHTEhkfLZsxpORrTlUyRQ-3XbE_BEKcYfQRJi4pYBhM-RyJh5vto02nuEpC819xkmCDQEIIMDo8WNmWp3pkrBpWeUqLKtoN-P_XVutc8S7wK-eADwQ';

const client = new ApolloClient({
  uri: 'https://api-us-west-2.graphcms.com/v2/ckelxtm6x1t2m01wh0w96adx2/master',
  request: operation => {
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <NewMuiThemeProvider theme={darkTheme}>
        <App />
      </NewMuiThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
