import React from 'react';
import { withRouter } from 'react-router';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Instagram from '@material-ui/icons/Instagram';
import Facebook from '@material-ui/icons/Facebook';
import Pinterest from '@material-ui/icons/Pinterest';

import FAQ from '../../assets/faq.png';
import Phone from '../../assets/PhoneIcon.png';
import Email from '../../assets/email.png';

import classes from './styles.module.scss';

function Footer({ history }) {
  return (
    <div>
      <Grid className={classes.rowFooter}>
        <Grid item xs={6} className={classes.footerLeft}>
          <p className={classes.leftP} style={{ paddingTop: '5%' }}>
            Owned by:
            <a href="https://www.pacificoutdoorliving.com/" className={classes.ownedBy}>
              Pacific Outdoor Living
            </a>
          </p>
          <p className={classes.license}>LICENSE:</p>
          <p className={classes.lic}>LIC #715731</p>
          <Grid className={classes.contactInfor}>
            <Grid item sm={6}>
              <p className={classes.leftPBottom} style={{ paddingTop: '5%' }}>
                Office and Showroom
              </p>
              <p className={classes.leftPBottom} style={{ paddingTop: '5%' }}>
                8309 Tujunga Ave Ste. 201
              </p>
              <p className={classes.leftPBottom}>Sun Valley, CA 91352, USA</p>
              <p className={classes.leftPBottom} style={{ paddingTop: '5%' }}>
                Phone: (866) 234-4036
              </p>
              <p className={classes.leftPBottom}>© 2020 YardMap</p>
              <p className={classes.leftPBottom}>All Rights Reserved.</p>
            </Grid>
            <Grid item sm={6} className={classes.social}>
              <Grid className={classes.marginRight20} align="center" fontSize="large">
                <Link href="https://www.facebook.com/YardMap/">
                  <Facebook className={classes.footerIcon} />
                </Link>
                <br />
                <Link href="https://www.facebook.com/YardMap/">Facebook</Link>
              </Grid>
              <Grid className={classes.marginRight20} align="center">
                <Link href="https://www.instagram.com/yardmap/">
                  <Instagram className={classes.footerIcon} />
                </Link>
                <br />
                <Link href="https://www.instagram.com/yardmap/">Instagram</Link>
              </Grid>
              <Grid className={classes.marginRight20} align="center">
                <Link href="https://www.pinterest.com/YardMapDesigns/">
                  <Pinterest className={classes.footerIcon} />
                </Link>
                <br />
                <Link href="https://www.pinterest.com/YardMapDesigns/">Pinterest</Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.footerRight}>
          <Grid container spacing="3" style={{ width: '100%' }}>
            <Grid item xs={3} align="center">
              <Link className={classes.secondColFooter} href="/about">
                About us
              </Link>
            </Grid>
            <Grid item xs={3} align="center">
              <Link className={classes.secondColFooter} href="/how">
                How it works
              </Link>
            </Grid>
            <Grid item xs={3} align="center">
              <Link className={classes.secondColFooter} href="/projects">
                Past Projects
              </Link>
            </Grid>
            <Grid item xs={3} align="center">
              <Link className={classes.secondColFooter} href="/pricing">
                Pricing
              </Link>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={3} align="center">
              <Link className={classes.secondColFooter} href="/policy">
                Privacy Policy
              </Link>
            </Grid>
            <Grid item xs={3} align="center">
              <Link className={classes.secondColFooter} href="/terms">
                Terms & Conditions
              </Link>
            </Grid>
            <Grid item xs={3} align="center">
              <Link className={classes.secondColFooter} href="/blogs">
                Blog
              </Link>
            </Grid>
            <Grid item xs={1} />
          </Grid>
          <h3 className={classes.contactUsFooter}>Need to contact us?</h3>
          <br />
          <p className={classes.subtextfooter}>
            We’re available by phone (866) 234-4036 every day from 9 a.m.–6 p.m. PT.
          </p>
          <div style={{ margin: 'auto' }}>
            <Grid item id={classes.SecondFooterBottomRow}>
              <p className={classes.secondColFooter}>
                <Link href="tel:8662344036">
                  <img src={Phone} className={classes.footerIcon} alt="" />
                </Link>
                <br />
                <br />
                <Link href="tel:8662344036">Call</Link>
              </p>
              <p style={{ height: 50, marginTop: 2 }} className={classes.secondColFooter}>
                <Link href="mailto:info@yardmap.com">
                  <img src={Email} alt="" className={classes.footerIcon} />
                </Link>
                <br />
                <br />
                <p style={{ paddingTop: 5 }}>
                  <Link href="mailto:info@yardmap.com">Email</Link>
                </p>
              </p>
              <p className={classes.secondColFooter}>
                <img
                  src={FAQ}
                  alt=""
                  className={classes.footerIcon}
                  onClick={() => {
                    history.push('/faq');
                    window.scrollTo(0, 0);
                  }}
                />
                <br />
                <br />
                <a href="/faq">FAQ</a>
              </p>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid className={classes.mobileFooter}>
        <Grid container className={classes.firstPart}>
          <Grid item xs={6}>
            <Grid item sm={12}>
              <p>
                Owned by:
                <br />
                <a
                  href="https://www.pacificoutdoorliving.com/"
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: '12px',
                    lineHeight: '14px',
                    textAlign: 'center',
                    color: '#535252',
                    textDecoration: 'underline',
                  }}
                >
                  Pacific Outdoor Living
                </a>
              </p>
              <p>LICENSE:</p>
              <p>LIC #715731</p>
              <Grid>
                <Grid item sm={12}>
                  <p>Office and Showroom</p>
                  <p> 8309 Tujunga Ave Ste. 201</p>
                  <p>Sun Valley, CA 91352, USA</p>
                  <p>Phone: (866) 234-4036 </p>
                  <p>© 2020 YardMap</p>
                  <p>All Rights Reserved.</p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item className={classes.footerMenu}>
              <Link className={classes.marginAuto} href="/about">
                <br />
                About us
              </Link>
              <Link className={classes.marginAuto} href="/how">
                How it works
              </Link>
              <Link className={classes.marginAuto} href="/projects">
                Past Projects
              </Link>
              <Link className={classes.marginAuto} href="/pricing">
                Pricing
              </Link>
              <Link className={classes.marginAuto} href="/policy">
                Privacy Policy
              </Link>
              <Link className={classes.marginAuto} href="/terms">
                Terms & Conditions
              </Link>
              <Link className={classes.marginAuto} href="/blogs">
                Blog
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} className={classes.secondPart}>
          <h3 className={classes.contactUs}>Need to contact us?</h3>
          <br />
          <p className={classes.contactTime}>
            We’re available by phone (866) 234-4036 every day from 9 a.m.–10 p.m. PT.
          </p>
          <div>
            <Grid item id={classes.SecondFooterBottomRow}>
              <p className={classes.secondColFooter}>
                <Link href="tel:8662344036">
                  <img src={Phone} alt="" className={classes.footerIcon} />
                </Link>
                <br />
                <br />
                <Link href="tel:8662344036" className={classes.mobileIconText}>
                  Call
                </Link>
              </p>
              <p style={{ height: 50, marginTop: 5 }} className={classes.secondColFooter}>
                <Link href="mailto:info@yardmap.com">
                  <img src={Email} alt="" className={classes.footerIcon} />
                </Link>
                <br />
                <br />
                <p style={{ paddingTop: 6 }}>
                  <Link href="mailto:info@yardmap.com" className={classes.mobileIconText}>
                    Email
                  </Link>
                </p>
              </p>
              <p className={classes.secondColFooter}>
                <img
                  src={FAQ}
                  alt=""
                  className={classes.footerIcon}
                  onClick={() => {
                    history.push('/faq');
                    window.scrollTo(0, 0);
                  }}
                />
                <br />
                <br />
                <a href="/faq" className={classes.mobileIconText}>
                  FAQ
                </a>
              </p>
            </Grid>
          </div>
          <Grid container className={classes.mobilesocial}>
            <Grid item xs={4} className={classes.marginRight20}>
              <Link href="https://www.facebook.com/YardMap">
                <Facebook className={classes.footerIcon} />
              </Link>
              <br />
              <Link href="https://www.facebook.com/YardMap" className={classes.mobileIconText}>
                Facebook
              </Link>
            </Grid>
            <Grid item xs={4} className={classes.marginRight20}>
              <Link href="https://www.instagram.com/yardmap/">
                <Instagram className={classes.footerIcon} />
              </Link>
              <br />
              <Link href="https://www.instagram.com/yardmap/" className={classes.mobileIconText}>
                Instagram
              </Link>
            </Grid>
            <Grid item xs={4} className={classes.marginRight20}>
              <Link href="https://www.pinterest.com/YardMapDesigns">
                <Pinterest className={classes.footerIcon} />
              </Link>
              <br />
              <Link
                href="https://www.pinterest.com/YardMapDesigns"
                className={classes.mobileIconText}
              >
                Pinterest
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(Footer);
