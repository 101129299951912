import React from 'react';
import ReactLoading from 'react-loading';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

const muiStyles = theme => ({
  container: {
    width: '100%',
    height: '100vh',
  },
});

const SuspenseFallback = ({ classes }) => {
  return (
    <Grid container className={classes.container} justify="center" alignItems="center">
      <ReactLoading type={'spinningBubbles'} color="#83b092" height={120} width={120} />
    </Grid>
  );
};

export default withStyles(muiStyles)(SuspenseFallback);
