const PRODUCTION_SERVER = 'https://yardmap-backend.herokuapp.com';
const STAGING_SERVER = 'https://yardmap-server-develop.herokuapp.com';

const baseURL =
  process.env.REACT_APP_DEV_ENV === 'dev'
    ? STAGING_SERVER
    : process.env.REACT_APP_DEV_ENV === 'prod'
    ? PRODUCTION_SERVER
    : 'http://localhost:2000';

export const login = async data => {
  const res = await fetch(`${baseURL}/api/user/login`, {
    method: 'Post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  return {
    status: res.status,
    data: await res.json(),
  };
};
export const signup = async data => {
  const res = await fetch(`${baseURL}/api/user/signup`, {
    method: 'Post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  return {
    status: res.status,
    data: await res.json(),
  };
};
export const getUserInfo = async data => {
  const res = await fetch(`${baseURL}/api/user`, {
    method: 'Get',
    headers: { 'Content-Type': 'application/json', authorization: localStorage.getItem('token') },
  });
  return {
    status: res.status,
    data: await res.json(),
  };
};

export const OrderPurchase = async data => {
  return await fetch(`${baseURL}/api/stripe/charge`, {
    method: 'Post',
    headers: {
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token'),
    },
    body: JSON.stringify(data),
  });
};
export const createPaymentIntents = async data => {
  return await fetch(`${baseURL}/api/stripe/createIntents`, {
    method: 'Post',
    headers: { 'Content-Type': 'application/json', authorization: localStorage.getItem('token') },
    body: JSON.stringify(data),
  });
};

export const getContactTagsList = async () => {
  return await fetch(`${baseURL}/api/activeCampaign/getAllTags`, {
    method: 'GET',
  });
};
export const addContact = async data => {
  return await fetch(`${baseURL}/api/activeCampaign/addContact`, {
    method: 'Post',
    headers: { 'Content-Type': 'application/json', authorization: localStorage.getItem('token') },
    body: JSON.stringify(data),
  });
};
export const addContactFromStripe = async data => {
  return await fetch(`${baseURL}/api/activeCampaign/addContactFromStripe`, {
    method: 'Post',
    headers: { 'Content-Type': 'application/json', authorization: localStorage.getItem('token') },
    body: JSON.stringify(data),
  });
};
export const addContactTag = async data => {
  return await fetch(`${baseURL}/api/activeCampaign/addContactTag`, {
    method: 'Post',
    headers: { 'Content-Type': 'application/json', authorization: localStorage.getItem('token') },
    body: JSON.stringify(data),
  });
};
